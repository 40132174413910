import { useState} from "react";
import style from "./DealForm.module.scss"
import Button from "../IU/Button/Button";
import RadioButton from "../IU/RadioButton/RadioButton";
import Select from "../IU/Select/Select";
import ImageModal from "../Modal/Images/ImageModal";
import {useMutation, useQuery} from "@apollo/client";
import {CREATE_AFFILIATE_TRIP, UPDATE_TRIP} from "../../graphql/mutations/trip";
import {GET_TRIP_CATEGORIES, GET_TRIP_CONTEXTS} from "../../graphql/queries/trip";
import {getTripContexts, getTripContextsVariables} from "../../graphql/queries/__generated__/getTripContexts";
import {getTripCategories, getTripCategoriesVariables} from "../../graphql/queries/__generated__/getTripCategories";
import {
    createAffiliateTrip,
    createAffiliateTripVariables
} from "../../graphql/mutations/__generated__/createAffiliateTrip";
import DateRangeInput from "../IU/DateRangeInput/DateRangeInput";
import InputWithIcon from "../IU/InputWithIcon/InputWithIcon";
import PriceInput from "../IU/PriceInput/PriceInput";
import InputError from "../IU/InputError/InputError";
import AddDealText from "../Modal/TextEditor/TextEditor";
import {updateTrip, updateTripVariables} from "../../graphql/mutations/__generated__/updateTrip";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {IResult} from "../IU/Dropdown/DropDown";
import moment from "moment/moment";
import AutoComplete from "../IU/AutoCompleteInput/AutoComplete";
import {GetTrip, GetTrip_trip} from "../../graphql/queries/__generated__/GetTrip";

export const TRAVEL_TYPE = [
    {id: 'full', name: 'Tout compris'},
    {id: 'flight', name: 'Vol'},
    {id: 'hotel', name: 'Hotel'},
    {id: 'bus', name: 'Bus'},
    {id: 'train', name: 'Train'},
]

export const TRAVEL_WITH = [
    {id: 'solo', name: 'Solo'},
    {id: 'duo', name: 'Duo'},
    {id: 'group', name: 'Group'},
]

export const CONTINENTS = [
    {id: 'africa', name: 'Afrique'},
    {id: 'south_america', name: 'Amérique du sud'},
    {id: 'north_america', name: 'Amérique du nord'},
    {id: 'asia', name: 'Asie'},
    {id: 'antarctic', name: 'Antartique'},
    {id: 'europe', name: 'Europe'},
    {id: 'oceania', name: 'Océanie'},
]


export const CREATOR = [
    {id: 'vip', name: 'Bro\'kit'},
    {id: 'public', name: 'Brokie'},
]

export const TRAVELERS_TYPE = [
    {id: 'solo', name: 'Solo'},
    {id: 'weekEnd', name: 'Week-end'},
    {id: 'vacances', name: 'Vacances scolaires'},
    {id: 'roadtrip', name: 'Roadtrip'},
]

export const DESCRIPTION_DETAILS = {
    title: "Rédiger la description",
    icon: {
        url: "/svg/description-icon.svg",
        alt: "description icon",
    }
}
export const ANECDOTE_DETAILS = {
    title: "Rédiger le Brok'Anecdote",
    icon: {
        url: "/svg/anecdote-icon.svg",
        alt: "anecdote icon",
    }
}

export interface CustomObj {
    id: string,
    name: string
}



const getDefaultValueForSimpleSelect = ( data, id) => {
    return data.filter(d=>d.id === id)[0]
}

export const TEN_HOURS_IN_TIMESTAMP = (10 * 60 * 60 * 1000)

export const changeToTimestampUtc = (dateToChange) =>{
    return moment(
        dateToChange
    ).set({hours: 13, minutes: 30}).unix();
}

export const encodeText = (text) => {
    return text.replace(/"/g, '\\"')
}

export const decodeText = (text) => {
    return text.replace('\\"', /"/g)
}

export const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const MySwal = withReactContent(Swal)

interface IDealFormAffiliateProps   {
    handleSubmit: any,
   // handleUpdateSubmit: (e: any, description: any, anecdote: any, travelTypeSelected: any, travelWithSelected: any, images: any, startDate: any, endDate: any, categoriesSelected: any, continentSelected: any, contextSelected: any, departureCity: any, arrivalCity: any, arrivalCountry: any, departurePlace: any, id: any)=> void,
    formErrors: any,
    deal?: any,
    deleteTripHandler?: (id: string)=>void
}
const DealFormAffiliate = ({ handleSubmit, formErrors, deal, deleteTripHandler}: IDealFormAffiliateProps) => {
    const [createAffiliateTrip] = useMutation<createAffiliateTrip, createAffiliateTripVariables>(CREATE_AFFILIATE_TRIP);
    const [updateTrip] = useMutation<updateTrip, updateTripVariables>(UPDATE_TRIP);
    const {
        loading: loadingContext,
        error: errorContext,
        data: dataContext
    } = useQuery<getTripContexts, getTripContextsVariables>(GET_TRIP_CONTEXTS, {variables: {page: 0, limit: 100}})
    const {
        loading: loadingCategories,
        error: errorCategories,
        data: dataCategories
    } = useQuery<getTripCategories, getTripCategoriesVariables>(GET_TRIP_CATEGORIES, {variables: {page: 0, limit: 100}})
    const [departurePlace, setDeparturePlace] = useState<any>(deal ? deal.departureStation : null)
    const [arrivalCountry, setArrivalCountry] = useState<IResult>(deal ? deal.country : null)
    const [arrivalPlace, setArrivalPlace] = useState<IResult>(deal ? deal.arrivalPlace : null)
    const [departureCity, setDepartureCity] = useState<IResult>(deal ? deal.departure : null)
    const [arrivalCity, setArrivalCity] = useState<IResult>(deal ? deal.arrival : null)
    const [startDate, setStartDate] = useState<Date>(deal ? new Date(deal.startDate * 1000) : new Date());
    const [endDate, setEndDate] = useState<Date>(deal ? new Date(deal.endDate * 1000) : new Date());
    const [images, setImages] = useState<any[]>(deal ? deal.medias : [])
    const [continentSelected, setContinentSelected] = useState<any>(deal ? getDefaultValueForSimpleSelect(CONTINENTS, deal.continent) : '')
    const [travelTypeSelected, setTravelTypeSelected] = useState<any>(deal ?  getDefaultValueForSimpleSelect(TRAVEL_TYPE, deal.type) : '')
    const [travelWithSelected, setTravelWithSelected] = useState<any>(deal ? getDefaultValueForSimpleSelect(TRAVEL_WITH, deal.travelWith) : '')
    const [categoriesSelected, setCategoriesSelected] = useState<any[]>(deal ? deal.categories : [])
    const [contextSelected, setContextSelected] = useState<any[]>(deal ? deal.contexts : [])

    const [description, setDescription] = useState(deal ? deal.longDescription : "");
    const [anecdote, setAnecdote] = useState(deal ? deal.shortDescription : "");
    const [openImageModal, setOpenImageModal] = useState(false);
    const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
    const [openAnecdoteModal, setOpenAnecdoteModal] = useState(false);
    const [isLowCarbon, setIsLowCarbon] = useState(false);


    if (loadingContext) return <div>Loading...</div>;
    if (errorContext) return <div>`Error! ${errorContext.message}`</div>;
    if (loadingCategories) return <div>Loading...</div>;
    if (errorCategories) return <div>`Error! ${errorCategories.message}`</div>;
    const categories = dataCategories?.tripCategories
    const contexts = dataContext?.tripContexts






    const previewHandler = () => {

    }

    return (

        <form className={style['form']} onSubmit={(e) =>{
            e.preventDefault()
            if (deal?.id){
                handleSubmit(e,description.replace(/ /g, '&nbsp;'), anecdote.replace(/ /g, '&nbsp;'), travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace, deal?.id)
            }else{
                handleSubmit(e,description, anecdote, travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace)
            }
        }}>

            <div className={style['form__wrapper']}>
                {openImageModal &&
                    <ImageModal setOpenModal={setOpenImageModal} setImages={setImages} images={images} fileType={'trip'}/> }
                {openAnecdoteModal &&
                    <AddDealText text={anecdote} setText={setAnecdote} closeModal={setOpenAnecdoteModal}
                                 type={ANECDOTE_DETAILS} />}
                {openDescriptionModal &&
                    <AddDealText text={description} setText={setDescription} closeModal={setOpenDescriptionModal}
                                 type={DESCRIPTION_DETAILS} />}
                <div className={style['form__wrapper--field']}>
                    <label>Lien d'affiliation</label>
                    <InputWithIcon name={"affiliation"}
                                   prefixIcon={{url: '/svg/affiliation-icon.svg', alt: 'affiliation icon'}} defaultValue={deal?.affiliationLink}/>
                    {formErrors?.hasOwnProperty("affiliationLink") && (
                        <InputError message="Saisis ton lien d'affiliation."/>
                    )}
                </div>
                <div className={style['form__wrapper--field']}>
                    <label> Type de bon plan</label>
                    <RadioButton name={"visibility"} data={CREATOR} defaultValue={deal?.visibility}/>
                    {formErrors?.hasOwnProperty("visibility") && (
                        <InputError message="Choisis."/>
                    )}
                </div>
                <div className={style['form__wrapper--field']}>
                    <label> Type de Voyage</label>
                    <Select data={TRAVEL_TYPE} value={travelTypeSelected} onChange={n => setTravelTypeSelected(n)}/>
                    {formErrors?.hasOwnProperty("type") && (
                        <InputError message="Choisis."/>
                    )}
                </div>
                <div className={style['form__wrapper__group']}>
                    <div className={style['form__wrapper--field']}>
                        <label htmlFor="nbTravelers">Nombre de voyageurs</label>
                        <input className={`${style['form__wrapper__input']}`} id="nbTravelers" name="nbTravelers"
                               type="number" defaultValue={deal?.numberOfPassengers} min={1}/>
                        {formErrors?.hasOwnProperty("numberOfPassengers") && (
                            <InputError message="Saisis ton nombre de voyageur."/>
                        )}
                    </div>
                    <div className={style['form__wrapper--field']}>
                        <label htmlFor="price">Prix</label>
                        <PriceInput priceName={"price"} currencyName={"currency"} defaultValue={{price: deal?.price, currency: deal?.currency}}/>
                        {formErrors?.hasOwnProperty("price") && (
                            <InputError message="Saisis le montant."/>
                        )}
                    </div>
                </div>
                <div className={style['form__wrapper--line']}></div>
                <div className={style['form__wrapper--title']}>Départ</div>
                <div className={style['form__wrapper__group']}>

                    <div className={style['form__wrapper--field']}>
                        <label htmlFor="departureCountry">Pays de départ</label>
                        <input
                            className={`${style['form__wrapper__input']} ${style['form__wrapper__input--disabled']}`}
                            name="departureCountry" type="text" value="France" id="ChIJMVd4MymgVA0R99lHx5Y__Ws"
                            readOnly={true}/>
                    </div>
                    <div className={`${style['form__wrapper--field']} ${style['form__wrapper--flex']}`}>
                        <label htmlFor="departureCity">Ville de départ</label>
                        <AutoComplete data={departureCity} name={"departureCity"} setData={setDepartureCity} type={"city"} defaultValue={deal?.departure.name}/>
                        {formErrors?.hasOwnProperty("departureId") && (
                            <InputError message="Saisis une ville de départ"/>
                        )}
                    </div>
                    <div className={`${style['form__wrapper--field']} ${style['form__wrapper--flex']}`}>
                        <label htmlFor="departurePlace">Lieu de départ</label>
                        <AutoComplete data={departurePlace} name={"departurePlace"} setData={setDeparturePlace} type={"flightCity"} defaultValue={deal?.departureStation}/>
                        {formErrors?.hasOwnProperty("departureAirport" ) && (
                            <InputError message="Saisis un lieu de depart"/>
                        )}
                    </div>
                </div>
                <div className={style['form__wrapper__group']}>
                     <div className={`${style['form__wrapper--field']} ${style['form__wrapper--flex']}`}>
                        <label htmlFor="searchPeriod">Période du deal</label>
                        <DateRangeInput startDate={startDate} setStartDate={setStartDate} endDate={endDate}
                                        setEndDate={setEndDate}/>
                         {formErrors?.hasOwnProperty("searchPeriod") && (
                             <InputError message="Saisir une periode de recherche"/>
                         )}
                    </div>
                </div>
                <div className={style['form__wrapper--line']}></div>
                <div className={style['form__wrapper--title']}>Arrivée</div>
                <div className={style['form__wrapper--field']}>
                    <label htmlFor="continent">Choisir un continent</label>
                    <Select data={CONTINENTS} value={continentSelected} onChange={n => setContinentSelected(n)}/>
                    {formErrors?.hasOwnProperty("continent") && (
                        <InputError message="Saisir un continent"/>
                    )}
                </div>
                <div className={style['form__wrapper__group']}>
                    <div className={style['form__wrapper--field']}>
                        <label htmlFor="arrivalCountry">Pays d'arrivée</label>
                        <AutoComplete data={arrivalCountry} name={"arrivalCountry"} setData={setArrivalCountry} type={"country"} defaultValue={deal?.country.name}/>
                        {formErrors?.hasOwnProperty("countryId") && (
                            <InputError message="Saisis un pays de d'arrivé"/>
                        )}
                    </div>
                    <div className={`${style['form__wrapper--field']} ${style['form__wrapper--flex']}`}>
                        <label htmlFor="arrivalCity">Ville d'arrivée</label>
                        <AutoComplete data={arrivalCity} name={"arrivalCity"} setData={setArrivalCity} type={"city"} defaultValue={deal?.arrival.name}/>
                        {formErrors?.hasOwnProperty("cityId") && (
                            <InputError message="Saisis une ville de d'arrivée"/>
                        )}
                    </div>
                    <div className={`${style['form__wrapper--field']} ${style['form__wrapper--flex']}`}>
                        <label htmlFor="arrivalPlace">Lieu d'arrivée</label>
                        <AutoComplete data={arrivalPlace} name={"arrivalPlace"} setData={setArrivalPlace} type={"flightCity"} />
                        {formErrors?.hasOwnProperty("arrivalPlace") && (
                            <InputError message="Saisis un lieu de d'arrivé"/>
                        )}
                    </div>
                </div>
                <div className={style['form__wrapper--line']}></div>
                <div className={style['form__wrapper__group']}>
                    <div className={style['form__wrapper--field']}>
                        <label htmlFor="images">Galerie d'images</label>
                        <Button
                            text={"Télécharger une image"}
                            color={"#2E3FD8"}
                            width={200}
                            height={50}
                            borderRadius={10}
                            backgroundColor={"#E0E6FB"}
                            prefixIcon={{
                                url: "/svg/image-icon.svg",
                                alt: "icône image",
                                style: {},
                            }}
                            onClick={() => {
                                setOpenImageModal(true)
                            }}
                            borderColor={"#5663DA"}/>
                        {formErrors?.hasOwnProperty("medias") && (
                            <InputError message="Selectionné ou importé des images pour le deal"/>
                        )}
                    </div>

                </div>
                <div className={style['form__wrapper__group']}>
                    <div className={style['form__wrapper--field']}>
                        <label htmlFor="description">Description</label>
                        <Button
                            text={"Écrire la description"}
                            color={"#2E3FD8"}
                            width={180}
                            height={50}
                            borderRadius={10}
                            backgroundColor={"#E0E6FB"}
                            prefixIcon={{
                                url: "/svg/description-icon.svg",
                                alt: "icône description",
                                style: {},
                            }}
                            onClick={() => {
                                setOpenDescriptionModal(true)
                            }}
                            borderColor={"#5663DA"} />
                        {formErrors?.hasOwnProperty("longDescription") && (
                            <InputError message="Saisir une description"/>
                        )}
                    </div>
                    <div className={style['form__wrapper--field']}>
                        <label htmlFor="description">Brok'Anecdote</label>
                        <Button
                            text={"Écrire un Brok'Anecdote"}
                            color={"#2E3FD8"}
                            width={220}
                            height={50}
                            borderRadius={10}
                            backgroundColor={"#E0E6FB"}
                            prefixIcon={{
                                url: "/svg/anecdote-icon.svg",
                                alt: "icône Brok'Anecdote",
                                style: {},
                            }}
                            onClick={() => {
                                setOpenAnecdoteModal(true)
                            }}
                            borderColor={"#5663DA"}/>
                        {formErrors?.hasOwnProperty("shortDescription") && (
                            <InputError message="Saisir une anecdote drole "/>
                        )}
                    </div>
                </div>
                <div className={`${style['form__wrapper--field']} ${style['form__wrapper--small']}`}>
                    <label htmlFor="categories">Categories du voyage</label>
                    <Select data={categories} value={categoriesSelected} onChange={n => setCategoriesSelected(n)}
                            isMultiple={true}/>
                    {formErrors?.hasOwnProperty("categories") && (
                        <InputError message="Saisir au moins une categorie "/>
                    )}
                </div>
                <div className={`${style['form__wrapper--field']} ${style['form__wrapper--small']}`}>
                    <label htmlFor="context">Contexte du voyage</label>
                    <Select data={contexts} value={contextSelected} onChange={n => setContextSelected(n)}
                            isMultiple={true}/>
                    {formErrors?.hasOwnProperty("contexts") && (
                        <InputError message="Saisir au moins un context "/>
                    )}

                </div>
                <div className={`${style['form__wrapper--field']} ${style['form__wrapper--small']}`}>
                    <label htmlFor="travelWith">Type d'offres</label>
                    <Select data={TRAVEL_WITH} value={travelWithSelected} onChange={n => setTravelWithSelected(n)}/>
                    {formErrors?.hasOwnProperty("contexts") && (
                        <InputError message="Saisir un Type d'offres "/>
                    )}
                </div>
            </div>
            <div className={style['form--buttons']}>

                { deal?.id &&
                    <Button
                    text={"Supprimer"}
                    color={"#FFF"}
                    backgroundColor={"#DD4646"}
                    width={140}
                    height={50}
                    onClick={() => {
                        if (deleteTripHandler) {
                            deleteTripHandler(deal?.id)
                        }
                    }}
                    borderRadius={10}
                />}
                <Button
                    text={"Preview"}
                    color={"#2D2C6C"}
                    width={120}
                    height={50}
                    borderRadius={10}
                    backgroundColor={"#FFF"}
                    onClick={previewHandler}
                    borderColor={"#2D2C6C"}/>
                <Button
                    text={"Soumettre"}
                    type={"submit"}
                    color={"#FFF"}
                    backgroundColor={"#2D2C6C"}
                    width={140}
                    height={50}
                    borderRadius={10}
                />
            </div>

        </form>
    );
}

export default DealFormAffiliate;