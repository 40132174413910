import style from "./AddCoupon.module.scss";
import React, { useEffect, useState } from "react";
import Button from "../../IU/Button/Button";
import { useMutation, useQuery } from "@apollo/client";
import Path from "../../Path/Path";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CheckboxRound from "src/components/IU/Checkbox-round/CheckboxRound";
import Select from "src/components/IU/Select/Select";
import { ListPlans } from "src/graphql/queries/__generated__/ListPlans";
import {
  GET_PLAN_BY_STRIPE_PRODUCT,
  LIST_PLANS,
} from "src/graphql/queries/plan";
import client from "src/graphql/client";
import { plan_plan } from "src/graphql/queries/__generated__/plan";
import { CREATE_COUPON } from "src/graphql/mutations/coupon";
import {
  createCoupon,
  createCouponVariables,
} from "src/graphql/mutations/__generated__/createCoupon";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormInput from "src/components/IU/FormInput/FormInput";
import InputField from "src/components/IU/InputField/InputField";
import { getCoupon_coupon } from "src/graphql/queries/__generated__/getCoupon";

type errors = {
  [key: string]: boolean;
};
interface CouponData {
  id?: string;
  name: string;
  amountOff: number | null;
  percentOff: number | null;
  products: string[];
  durationInMonths?: number | null;
}

type AddCouponProps = {
  coupon?: getCoupon_coupon;
};

const schema = yup.object().shape({
  name: yup.string().required("requis"),
  amountOff: yup.number().nullable(),
  percentOff: yup.number().nullable(),
  durationInMonths: yup.number().required("requis"),
  value: yup.number().required("requis"),
  products: yup
    .array()
    .of(yup.string())
    .required("requis")
    .test("is-valid-products", "requis", (value) => {
      // Check if the value is an array and not empty
      if (!Array.isArray(value) || value.length === 0 || value[0] === "") {
        return false; // Fails validation if the value is not an array or is empty
      }

      // Check if the array contains undefined values
      if (value.every((item) => item === undefined)) {
        return false; // Fails validation if undefined values are present in the array
      }

      return true; // Passes validation if the array is not empty and does not contain undefined values
    }),
});

const AddCoupon = ({ coupon }: AddCouponProps) => {
  const choices = [
    { id: "Pourcentage", name: "Pourcentage" },
    { id: "Amount", name: "Amount" },
  ];

  const {
    handleSubmit,
    setError,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: "",
      amountOff: null,
      percentOff: null,
      products: [],
      durationInMonths: null,
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [createCoupon] = useMutation<createCoupon, createCouponVariables>(
    CREATE_COUPON
  );
  // const [updateCoupon] = useMutation<updateCoupon, updateCouponVariables>(
  //   UPDATE_COUPON
  // );

  const [typeSelected, setTypeSelected] = useState<any>(
    coupon
      ? coupon?.percentOff
        ? { id: "Pourcentage", name: "Pourcentage" }
        : { id: "Amount", name: "Amount" }
      : choices[0]
  );
  const [products, setProducts] = useState<any>(coupon ? coupon?.products : []);
  const handleVIPOffersChange = (stripePriceID: string) => {
    setProducts((prevProducts: string[]) => {
      const isExisting = prevProducts.includes(stripePriceID);
      if (isExisting) {
        return prevProducts.filter((id: string) => id !== stripePriceID);
      } else {
        return [...prevProducts, stripePriceID];
      }
    });
  };

  const fetchCouponProductsDetails = async () => {
    if (!coupon) return;

    try {
      const productDetails = coupon.products && await Promise.all(
          coupon.products.map(async (productId) => {
          try {
            const { data: plan } = await client.query<plan_plan>({
              query: GET_PLAN_BY_STRIPE_PRODUCT,
              variables: {
                stripePriceID: productId,
              },
              fetchPolicy: "network-only",
            });
            return plan;
          } catch (error) {
            console.error(
              `Error fetching plan for product ID ${productId}:`,
              error
            );
            return null;
          }
        })
      );
      return productDetails?.filter(Boolean); // Remove null values
    } catch (err) {
      console.error("Error fetching product details:", err);
    }
  };

  useEffect(() => {
    if (coupon) {
      fetchCouponProductsDetails();
    }
  }, [coupon]);

  useEffect(() => {
    if (products.length > 0) {
      clearErrors("products");
    }
  }, [products]);

  const navigate = useNavigate();
  const {
    loading: loadingPlans,
    error: errorPlans,
    data: plans,
  } = useQuery<ListPlans>(LIST_PLANS);
  if (loadingPlans) return <div>Loading...</div>;
  if (errorPlans) return <div>`Error! ${errorPlans.message}`</div>;

  const MySwal = withReactContent(Swal);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const onSubmit: SubmitHandler<CouponData> = async (data: any) => {
    const value = data.value ?? null;
    if (products.length === 0) {
      setError("products", {
        type: "manual",
        message: "requis",
      });
      return;
    }
    const updatedData = {
      ...data,
      percentOff: typeSelected.name === "Pourcentage" ? value : null,
      amountOff: typeSelected.name === "Amount" ? value : null,
    };

    if (Object.keys(errors).length !== 0) {
      return;
    }
    if (!coupon) {
      await createCoupon({
        variables: {
          name: data.name,
          percentOff: updatedData.percentOff,
          amountOff: updatedData.amountOff,
          durationInMonths: updatedData.durationInMonths,
          products: products,
        },
      })
        .then(() => {
          Toast.fire({
            icon: "success",
            title: "Coupon créé",
          });
          navigate("/coupon");
        })
        .catch((e) =>
          MySwal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong: \n " + e.message,
          })
        );
    } else {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Cannot update coupon",
      });

      // try {
      // await updateCoupon({
      // variables: {
      // coupon: formData,
      // code: coupon.code,
      //         },
      //       });

      //       await Toast.fire({
      //         icon: "success",
      //         title: "Coupon à jour",
      //       });
      //       navigate("/coupon");
      //     } catch (e) {
      //       console.error(e);

      //       await MySwal.fire({
      //         icon: "error",
      //         title: "Oops...",
      //         // @ts-ignore
      //         text: "Something went wrong: \n " + e.message,
      //       });
      //     }
    }
  };

  return (
    <React.Fragment>
      <Path />
      <form className={style["form"]} onSubmit={handleSubmit(onSubmit)}>
        <div className={style["form-wrapper"]}>
          <div className={style["form-group"]}>
            <label htmlFor={"code"}>Code</label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <InputField
                  id={field.name}
                  name={field.name}
                  type="text"
                  clas={style["form-input"]}
                  autoComplete="on"
                  control={control}
                  errorClass={style["form-error"]}
                />
              )}
            />
          </div>

          <div className={style["form-group"]}>
            <label htmlFor={"type"}>Type</label>
            <Select
              data={choices}
              onChange={(e) => {
                setTypeSelected(e);
              }}
              value={typeSelected}
              withoutIcon={false}
              isCrossIcon={false}
            />
            {!typeSelected && <p className={style["form-error"]}>requis</p>}
          </div>

          <div className={style["form-group"]}>
            <label htmlFor={"value"}>Value</label>
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <InputField
                  id={field.name}
                  name={field.name}
                  type="text"
                  clas={style["form-input"]}
                  autoComplete="on"
                  control={control}
                  errorClass={style["form-error"]}
                />
              )}
            />
          </div>

          <div className={style["form-group"]}>
            <label htmlFor={"products"}>VIP Offers</label>
            {plans?.plans?.map((field, index, array) => {
              const isChecked =
                products?.includes(field.stripePriceID) ?? false;
              return (
                <div
                  key={field.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <CheckboxRound
                      id={field.stripePriceID}
                      name={`products[${index}]`}
                      isChecked={isChecked}
                      control={control}
                      setIsChecked={() =>
                        handleVIPOffersChange(field.stripePriceID)
                      }
                      errorClass={style["form-error"]}
                    />
                    <label
                      htmlFor={field.stripePriceID}
                      style={{ cursor: "pointer" }}
                    >
                      {field.name.charAt(0).toUpperCase() + field.name.slice(1)}
                    </label>
                  </div>
                </div>
              );
            })}
            {errors?.products && typeof errors.products === "object" && (
              <p className={style["form-error"]}>
                {errors.products.message?.toString()}
              </p>
            )}
          </div>
          <div className={style["form-group"]}>
            <label htmlFor={"durationInMonths"}>Max use</label>
            <Controller
              name="durationInMonths"
              control={control}
              render={({ field }) => (
                <InputField
                  id={field.name}
                  name={field.name}
                  type="text"
                  clas={style["form-input"]}
                  autoComplete="on"
                  control={control}
                  errorClass={style["form-error"]}
                />
              )}
            />
          </div>
        </div>
        <div className={style["form-button"]}>
          <Button
            text={coupon ? "Mettre à jour" : "Ajouter un coupon"}
            type={"submit"}
            color={"#FFF"}
            backgroundColor={"#2D2C6C"}
            width={140}
            height={50}
            borderRadius={10}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

export default AddCoupon;
