import style from './Modal.module.scss'

interface IModalProps {
    children: JSX.Element|JSX.Element[];
    setOpenModal: any
}

const Modal = ({ setOpenModal, children }: IModalProps) => {

    const clickHandler = (e: React.MouseEvent<HTMLDivElement>) =>{

        if (e.currentTarget === e.target){
            setOpenModal(false)
        }
    }

    return(
        <div className={style['modal']} onClick={clickHandler} id="outside">
            <div className={style['modal-wrapper']}>
                {children}
            </div>
        </div>
    )
}
export default Modal;