import style from "./AutoCompleteInput.module.scss"
import React, {useEffect, useRef, useState} from "react";
import {useLazyQuery} from "@apollo/client";
import {
    countries,
    countries_countries,
    countriesVariables
} from "../../../graphql/queries/__generated__/countries";
import {GET_COUNTRIES} from "../../../graphql/queries/country";
import {cities, cities_cities, citiesVariables} from "../../../graphql/queries/__generated__/cities";
import {GET_CITIES, GET_FLIGHT_CITY} from "../../../graphql/queries/city";
import {
    flightCities,
    flightCitiesVariables
} from "../../../graphql/queries/__generated__/flightCities";
import {LIMIT_PAGINATION} from "../Pagination/Pagination";


interface IAutoCompleteInputProps {
    data: any
    setData: any
    name: string
    type: string
    defaultValue?: cities_cities|countries_countries | string
    isDisable?: boolean
    isFocus: boolean
    setIsFocus: (val: boolean) => void
    placeholder?: string

}

const AutoCompleteInput = ({data,setData, type, name, defaultValue, placeholder, isFocus, setIsFocus, isDisable = false}: IAutoCompleteInputProps) => {
    const [value, setValue] = useState(defaultValue ? (typeof defaultValue === "string" ? defaultValue : defaultValue.name): "")
    const [isSelected, setIsSelected] = useState(false)
    const [searchCountries] = useLazyQuery<countries, countriesVariables>(GET_COUNTRIES)
    const [searchCities] = useLazyQuery<cities, citiesVariables>(GET_CITIES)
    const [searchFlightCities] = useLazyQuery<flightCities, flightCitiesVariables>(GET_FLIGHT_CITY)
    const [suggestions, setSuggestions] = useState<any[]>([])
    const wrapper:any = useRef()

    useEffect(() => {
        const onClickWindow = (e: any) => {
            if (wrapper?.current?.contains(e.target as Node)) {
                return;
            }
            setIsFocus(false);
        };

        window.addEventListener("click", onClickWindow);

        return () => window.removeEventListener("click", onClickWindow);
    }, []);

    useEffect(()=> {
        setValue((typeof defaultValue === "string") ? defaultValue : "")
    },[defaultValue])

    const onChangeHandler = async (e:any) => {
        const stg = e.target.value.trimStart()
        setValue(stg)
        if (stg.length > 2)
        {
            if (type === "country") {
                const res = await searchCountries({variables: {country: stg}}).catch(e=>console.log(e))
                if (res?.data?.countries)
                setSuggestions(res?.data?.countries)
            } else if (type === "city") {
                const res = await searchCities({variables: {city: stg, page:0, limit: LIMIT_PAGINATION}}).catch(e=>console.log(e))
                if (res?.data?.cities)
                setSuggestions(res?.data?.cities)
            } else {
                const res = await searchFlightCities({variables: {city: stg}})
                if (res?.data?.flightCities)
                setSuggestions(res?.data?.flightCities)
            }
        }

    }

    const onSuggestHandler = (object: any) => {
        if (type === 'city'){
            setValue(object?.name + ', ' + object?.stateName + ', ' + object?.countryName)
        } else {
            setValue(object?.name)
        }

        setData(object)
        setIsSelected(true)
        setSuggestions([])
    }
    const getSuggestionRender = (suggestion: any) => {

        switch (type){
            case "country":
                return suggestion?.name
            case "city":
                return suggestion?.name + ', ' + suggestion?.stateName + ', ' + suggestion?.countryName
            case "flightCity":
                return suggestion?.name
        }


    }


    return(
        <div className={style['wrapper']} ref={wrapper}  onClick={() => setIsFocus(true)}>
            <input type="text" name={name} onBlur={()=>{if (!data){setValue("")}}} className={`${isDisable && style['disable']}`} onChange={e => onChangeHandler(e)} value={value} readOnly={isDisable} placeholder={placeholder && placeholder}/>
            { (suggestions.length !== 0 && isFocus) && <div className={style["wrapper-suggestions"]}>
                <ul className={style["wrapper-suggestions-choices"]}>
                    {suggestions.map((suggestion, i: number) =>{
                        return (
                            <li
                                key={i}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onSuggestHandler(suggestion);
                                }}
                            >
                                {getSuggestionRender(suggestion)}
                            </li>
                        );
                    })}
                </ul>
            </div>
            }

        </div>
    )
}

export default AutoCompleteInput