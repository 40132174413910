import {cities_cities} from "../../../graphql/queries/__generated__/cities";
import style from "./AutoCompleteInput.module.scss"
import {countries_countries} from "../../../graphql/queries/__generated__/countries";
import { useRef, useState} from "react";
import AutoCompleteInput from "./AutoCompleteInput";

interface IAutoCompleteProps {
    data: any
    setData: any
    type: string
    name: string
    defaultValue?: cities_cities|countries_countries | string
    isDisable?: boolean
    placeholder?: string

}
const AutoComplete = ({data, setData, name, type, defaultValue, placeholder}: IAutoCompleteProps) => {
    const wrapper: any = useRef()
    const [isFocus, setIsFocus] = useState(true)

    return(
        <div className={style['wrapper']} ref={wrapper}>
            <AutoCompleteInput data={data} name={name} setData={setData} type={type} isFocus={isFocus} setIsFocus={setIsFocus}
            defaultValue={defaultValue} placeholder={placeholder}/>
        </div>
    )
}

export default AutoComplete