import React from "react";
import style from "./ListElement.module.scss";
import ListOptions from "./ListOptions";
import Pagination, {LIMIT_PAGINATION} from "./IU/Pagination/Pagination";
import moment from "moment/moment";
import "moment/locale/fr";

const getDate = (trip, period) => {
    if (trip?.provider === 'duffel'){
        if (period === 'start'){
            return trip?.outbound[0]?.arrivingAt
        }
        return trip?.inbound[trip?.inbound?.length -1 ]?.departingAt
    }
    if (period === 'start'){
        return trip?.startDate
    }
    return trip?.endDate

}

const ListElement = ({trips, page, setPage, totalOfDeals}: any) => {

        return (
            <div className={style['container']}>
                <table className={style['container-table']} cellSpacing="0">
                    <thead>
                    <tr className={style['container-table-header']}>
                        <th className={style['container-table-header-id']}>#</th>
                        <th className={style['container-table-header-visibility']}>Type</th>
                        <th className={style['container-table-header-city']}>Ville</th>
                        <th className={style['container-table-header-coutry']}>Pays</th>
                        <th className={style['container-table-header-price']}>Prix</th>
                        <th className={style['container-table-header-lowCarbon']}>Éco</th>
                        <th className={style['container-table-header-departure']}>Ville de départ</th>
                        <th className={style['container-table-header-departureStation']}>Station de départ</th>
                        <th className={style['container-table-header-nbTravelers']}>Nb de voyageurs</th>
                        <th className={style['container-table-header-duration']}>Durée</th>
                        <th className={style['container-table-header-date']}>Date</th>
                        <th className={style['container-table-header-tripType']}>Type de Voyage</th>
                        <th className={style['container-table-header-provider']}>Partenaire</th>
                        <th className={style['container-table-header-provider']}>Activé / Désactivé</th>
                        <th className={style['container-table-header-createdAt']}>Date de création</th>
                        <th className={style['container-table-header-provider']}>Derniére modification</th>
                        <th className={style['container-table-header-options']}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {trips?.map((deal)=>(
                        <tr key={deal.id} >
                            <td className={style['container-table-header-id']} data-label="Id">{deal?.id}</td>
                            <td className={`${style['container-table-header-visibility']} ${deal?.visibility === 'public' ? style['container-table-header-visibility-public'] : style['container-table-header-visibility-private']}` } data-label="Type">{deal?.visibility.charAt(0).toUpperCase() + deal?.visibility.slice(1)}</td>
                            <td className={style['container-table-header-city']} data-label="City">{deal?.city?.name}</td>
                            <td className={style['container-table-header-country']} data-label="Country">{deal?.country?.name}</td>
                            <td className={style['container-table-header-price']} data-label="Price">{deal?.price.toFixed(2)} €</td>
                            <td className={`${style['container-table-header-visibility']} ${deal?.lowCarbonTravel ? style['container-table-header-visibility-public'] : style['container-table-header-visibility-private']}` } data-label="LowCarbon" >{deal?.lowCarbonTravel ? "Oui" : "Non"}</td>
                            <td className={style['container-table-header-departure']} data-label="Departure">{deal?.departure?.name}</td>
                            <td className={style['container-table-header-departureStation']} data-label="departureStation">{<span>{deal?.departureStation}</span>}</td>
                            <td className={style['container-table-header-nbTravelers']} data-label="NbTravelers">{deal?.numberOfPassengers}</td>
                            <td className={style['container-table-header-duration']} data-label="Duration">{`${deal?.numberOfDays} jours - ${deal?.numberOfNights} nuits`}</td>
                            <td className={style['container-table-header-duration']} data-label="Date">{`${moment.unix(deal?.startDate).utc().locale('fr').format("DD MMMM YYYY")} - ${moment.unix(deal?.endDate).utc().locale('fr').format("DD MMMM YYYY")}`}</td>
                            <td className={style['container-table-header-tripType']} data-label="Type">{deal?.type.charAt(0).toUpperCase() + deal.type.slice(1)}</td>
                            <td className={style['container-table-header-provider']} data-label="Provider">{deal?.provider}</td>
                            <td className={`${style['container-table-header-visibility']} ${deal?.deletedAt === 0 ? style['container-table-header-visibility-public'] : style['container-table-header-visibility-private']}` } data-label="Provider">{deal?.deletedAt === 0 ? "Activé" : "Désactivé"}</td>
                            <td className={style['container-table-header-createdAt']} data-label="CreatedAt">{moment.unix(deal?.createdAt).utc().locale('fr').format("DD MMMM YYYY")}</td>
                            <td className={style['container-table-header-updatedAt']} data-label="UpdatedAt">{moment.unix(deal?.updatedAt).utc().locale('fr').format("DD MMMM YYYY")}</td>
                            <td className={style['']}>
                                <ListOptions data={deal} page={page}/>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={17}>
                                <Pagination page={page} setPage={setPage} isEmpty={trips?.length === 0 || !trips || trips?.length < LIMIT_PAGINATION} totalOfItems={totalOfDeals}/>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )

}

export default ListElement;