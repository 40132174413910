import { Fragment, useState,} from "react";
import style from './newDeal.module.scss'
import Path from "../Path/Path";
import DealFormAffiliate, {changeToTimestampUtc, encodeText, MySwal, Toast, TRAVEL_TYPE} from "./DealFormAffiliate";
import {CreateTripInput, UpdateTripInput} from "../../../__generated__/globalTypes";
import {useMutation} from "@apollo/client";
import {CREATE_AFFILIATE_TRIP, UPDATE_TRIP} from "../../graphql/mutations/trip";
import {updateTrip, updateTripVariables} from "../../graphql/mutations/__generated__/updateTrip";
import {
    createAffiliateTrip,
    createAffiliateTripVariables
} from "../../graphql/mutations/__generated__/createAffiliateTrip";
import {useNavigate} from "react-router-dom";
export type errors = {
    [key: string]: boolean;
};

export const diffDays = (startDate: Date, endDate: Date): number => {
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}
const NewDeal = ()  => {
    const [hide, setHide] = useState(false)
    const [errors, setErrors] = useState<any>({});
    const [createAffiliateTrip] = useMutation<createAffiliateTrip, createAffiliateTripVariables>(CREATE_AFFILIATE_TRIP);
    const [updateTrip] = useMutation<updateTrip, updateTripVariables>(UPDATE_TRIP);
    const navigate = useNavigate()

    const getErrorsFromFormData = (formData) =>{
        const emptyFields: errors = {};
        Object.keys(formData).forEach((field: string) => {
            if (formData[field] === "" || formData[field] == undefined || formData[field] == null) {
                emptyFields[field] = true;
            }
            if (field === 'contexts' || field === 'categories' || field === 'medias'){
                if(formData[field]?.length === 0){
                    emptyFields[field] = true;
                }
            }
        });
        if (formData.startDate === formData.endDate){
            emptyFields['searchPeriod'] = true
        }
        if (
            Object.keys(emptyFields).length > 0
        ) {
            setErrors({...emptyFields});
            return null;
        }

        setErrors({});
    }
    const getFormDataForOther = (e, description, anecdote, travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace): any => {
        const nbOfDays = diffDays(startDate, endDate)
        const formData: CreateTripInput | UpdateTripInput  = {
            affiliationLink: e.target['affiliation'].value,
            longDescription: encodeText(description),
            arrivalId: arrivalCity.id,
            categories: categoriesSelected?.map(c => c.id),
            cityId: arrivalCity.id,
            continent: continentSelected?.id,
            countryId: arrivalCountry.id,
            currency: "EUR",
            departureId: departureCity.id,
            departureStation: departurePlace.iataCode,
            returnId: departureCity.id,
            endDate: changeToTimestampUtc(endDate),
            medias: images?.map(image => (image?.id)),
            numberOfPassengers: parseInt(e.target['nbTravelers'].value),
            numberOfDays: nbOfDays,
            numberOfNights: nbOfDays - 1,
            price: parseFloat(e.target['price'].value),
            shortDescription: encodeText(anecdote),
            startDate: changeToTimestampUtc(startDate),
            type: travelTypeSelected?.id,
            travelWith: travelWithSelected?.id,
            visibility: e.target['visibility'].value,
            lowCarbonTravel: (travelTypeSelected?.id === TRAVEL_TYPE[3].id || travelTypeSelected?.id === TRAVEL_TYPE[4].id) ,
            contexts: contextSelected?.map(c => c.id),
        };
        getErrorsFromFormData(formData)
        if (Object.keys(errors).length > 0){
            return
        }

        return formData;
    }


    const formCreateHandler = async (e, description, anecdote, travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace ) => {
        e.preventDefault();
        const formData =  getFormDataForOther(e, description, anecdote, travelTypeSelected, travelWithSelected, images, startDate, endDate, categoriesSelected, continentSelected, contextSelected, departureCity, arrivalCity, arrivalCountry, departurePlace);
        if(!formData){
            return
        }
            try {
                await createAffiliateTrip({
                    variables: {
                        trip: formData
                    },
                })

                await Toast.fire({
                    icon: 'success',
                    title: 'Deal crée'
                })
                navigate('/deals')

            } catch(e) {
                await MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    // @ts-ignore
                    text: 'Something went wrong: \n ' + e.message,
                })
            }

        if (Object.keys(errors).length !==0){
            await MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                // @ts-ignore
                text: 'Something went wrong: \n ' + errors[0],
            })
        }


    }

    return (
        <Fragment>
            <Path/>
            <div className={style['wrapper']}>

                    <Fragment>
                        <div className={style['wrapper-header']}>
                            <span className={style['wrapper-header-title']}> Bon plan</span>
                        </div>
                        <div className={style['wrapper-line']}></div>
                    </Fragment>
                 <DealFormAffiliate handleSubmit={formCreateHandler} formErrors={errors} deleteTripHandler={()=>{}}/>

            </div>

        </Fragment>
    );
}

export default NewDeal;