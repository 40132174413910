import style from './UsersList.module.scss'
import React, {useEffect, useRef, useState} from "react";
import {getUsers, getUsers_users, getUsersVariables} from "../../../graphql/queries/__generated__/getUsers";
import ListOptions from "../../ListOptions";
import Pagination, {LIMIT_PAGINATION} from "../../IU/Pagination/Pagination";
import {useQuery} from "@apollo/client";
import {GET_USERS} from "../../../graphql/queries/user";
import Loader from "../../IU/Loader/Loader";
import {STRIPE_DASHBOARD_URL} from "../../../env";

const UsersList = ({filters, page, setPage}: any) => {
    const options: any = useRef()
    const [isOptionsActivated, setIsOptionsActivated] = useState(false)
    const {loading, error, data} = useQuery<getUsers, getUsersVariables>(GET_USERS, { variables: {page: page*LIMIT_PAGINATION, limit: LIMIT_PAGINATION, sort: filters}, fetchPolicy: "network-only"})
    useEffect(() => {
        const onClickWindow = (e: any) => {
            if (options?.current?.contains(e?.target as Node)) {
                return;
            }

            setIsOptionsActivated(false);
        };
        window.addEventListener("click", onClickWindow);

        return () => window.removeEventListener("click", onClickWindow);
    }, []);
    if (loading) return <div className={style['container']}><Loader/></div>;
    if (error) return <div>`Error! ${error.message}`</div>;
    const users = data?.users
    const userHandler = (e:React.MouseEvent<HTMLTableRowElement>, user: getUsers_users) =>{

    }
    const handleOnManageSubscription = (customerStripeID: string) => {
        window.open(`${STRIPE_DASHBOARD_URL}/${customerStripeID}`, '_blank')
    }

    return(
        <div className={style['container']}>
            <table className={style['container-table']} cellSpacing="0">
                <thead>
                <tr className={style['container-table-header']}>
                    <th className={style['']}>#</th>
                    <th className={style['']}>Nom d'utilisateur</th>
                    <th className={style['']}>Telephone</th>
                    <th className={style['']}>Rôle</th>
                    <th className={style['']}>Compte Activé</th>
                    <th className={style['']}>Date de l'inscription</th>
                    <th className={style['']}>Souscription</th>
                    <th className={style['']}>Action</th>

                </tr>
                </thead>
                <tbody>
                {users?.map((user)=>(
                    <tr key={user?.id} onClick={((e)=> userHandler(e,user))}>
                        <td className={style['']}>{user?.id}</td>
                        <td className={style['']}>
                            <div className={style['container-table-username']}>
                                <div className={style['container-table-username-pics']}>
                                    <img src={user?.media?.url ? user?.media.url : '/img/default-profile-pic.png'} alt={"profile pics"}/>
                                </div>
                                <div className={style['container-table-username-infos']}>
                                    <div className={style['container-table-username-infos-username']}>
                                        {user?.username}
                                    </div>
                                    <div className={style['container-table-username-infos-mail']}>
                                        {user?.email}
                                    </div>
                                </div>

                            </div>
                        </td>
                        <td className={style['']}>{user?.phoneNumber}</td>
                        <td className={style['container-table-role']}>
                            {user?.roles.map(role =>
                                <div className={`${role.name === 'USER' ? style['container-table-role-user'] : style['container-table-role-admin']}`}>
                                    {role.name}
                                </div>
                            )}
                        </td>
                        <td className={style['']}>
                            {user?.accountActivated && <div className={`${style['container-table-account']} ${style['container-table-valid']}`}><img src='/svg/validate.svg' alt='valid icon'/> Activé</div> }
                            {!user?.accountActivated &&<div className={`${style['container-table-account']} ${style['container-table-invalid']}`}><img src='/svg/incorrect.svg' alt='invalid icon'/> Desactivé</div>  }
                        </td>
                        <td className={style['']}>{new Date(user?.createdAt * 1000).toLocaleDateString()}</td>
                        <td className={style['link']} onClick={() => {
                            handleOnManageSubscription(user?.stripeCustomerId || "")
                        }
                        }> Gérer la souscription</td>
                        <td className={style['']} ref={options}>
                            <ListOptions data={user} page={page}/>
                        </td>

                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={15}>
                        <Pagination page={page} setPage={setPage} isEmpty={!users || users.length === 0 }/>
                    </td>
                </tr>
                </tfoot>
            </table>


        </div>
    )

}

export default UsersList