import style from './Users.module.scss'
import Path from "../../Path/Path";
import Button from "../../IU/Button/Button";
import React, { useState} from "react";
import {useNavigate} from "react-router-dom";
import UsersList from "./UsersList";
import Stats from "../../Stats/Stats";
import {SortAndFilter} from "../../../../__generated__/globalTypes";

const Users = () => {
    const navigate = useNavigate()
    const newDealHandler = () =>{
        navigate('/users/new-user')
    }
    const queryParameters = new URLSearchParams(window.location.search)
    const p = queryParameters.get("p")
    const [page, setPage] = useState(p ? parseInt(p)-1 : 0)
    const [filters, setFilters] = useState<SortAndFilter>({
        fields: 'created_at=+'
    })

    const onChangeHandler = (e) => {
        if (e.target.value.length > 2){

            setFilters({
                filters: `email=${e.target.value}`,
                fields: 'created_at=+'
            })
        }
        if (e.target.value.length === 2){
            setFilters({
                fields: 'created_at=+'
            })
        }
    }
    return(
        <div className={style['wrapper']}>
            <div className={style['wrapper-header']}>
                <Path/>
                <div className={style['wrapper-header-buttons']}>
                    <Button
                        text={"Filtrer"}
                        color={"#343E92"}
                        width={90}
                        height={40}
                        borderRadius={10}
                        borderColor={"#343E92"}
                        backgroundColor={"transparent"}
                        prefixIcon={{
                            url:"/svg/filter.svg",
                            alt:"filter",
                        }}
                    />
                    <Button
                        text={"Ajouter un utilisateur"}
                        color={"#FFF"}
                        width={170}
                        borderRadius={10}
                        backgroundColor={"#343E92"}
                        prefixIcon={{
                            url:"/svg/add.svg",
                            alt:"add",
                        }}
                        onClick={newDealHandler}
                    />
                </div>

            </div>
            <div className={style['wrapper-stats']}>
                <Stats title={"Nombre d'utilisateurs au total"} number={3068} variation={"10%"}/>
                <Stats title={"Nombre d'abonnés au Bro’Kit"} number={700} variation={"10%"}/>
                <Stats title={"Utilisateurs actifs à présent"} number={100} variation={"10%"}/>
            </div>
            <input className={style["input"]} type={"text"} placeholder={"Entrer une adresse email"} onChange={onChangeHandler}/>
            <UsersList filters={filters} page={page} setPage={setPage}/>
        </div>
    )

}

export default Users