import style from "./ListOptions.module.scss";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DELETE_TRIP,
  DELETE_TRIP_CATEGORY,
  DELETE_TRIP_CONTEXT,
} from "../graphql/mutations/trip";
import {
  GET_TRIP_CATEGORIES,
  GET_TRIP_CONTEXTS,
  LIST_TRIP,
} from "../graphql/queries/trip";
import { LIMIT_PAGINATION } from "./IU/Pagination/Pagination";
import { SHADOW_USER } from "../graphql/queries/user";
import { MySwal, Toast } from "./Deals/DealFormAffiliate";
import { DELETE_CITY, DELETE_COUNTRY } from "../graphql/mutations/city";
import { GET_ALL_CITIES } from "../graphql/queries/city";
import { GET_ALL_COUNTRIES } from "../graphql/queries/country";
import { DELETE_COUPON } from "src/graphql/mutations/coupon";
import { GET_COUPONS } from "src/graphql/queries/coupon";

const ListOptions = ({ data, page, setData, isCity = false }: any) => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();
  const [isOptionsActivated, setIsOptionsActivated] = useState(false);
  const options: any = useRef();
  const isAUser = path.includes("users");
  const [deleteTrip] = useMutation(DELETE_TRIP);
  const [deleteCity] = useMutation(DELETE_CITY);
  const [deleteCountry] = useMutation(DELETE_COUNTRY);
  const [shadowUser] = useLazyQuery(SHADOW_USER);
  const [deleteCoupon] = useMutation(DELETE_COUPON);

  const removeCoupon = (id) => {
    deleteCoupon({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: GET_COUPONS,
          variables: {
            page: page,
            limit: LIMIT_PAGINATION,
          },
        },
      ],
    });
  };
  const removeCity = (id) => {
    deleteCity({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: GET_ALL_CITIES,
          variables: { id: page * LIMIT_PAGINATION, limit: LIMIT_PAGINATION },
        },
      ],
    });
  };
  const removeCountry = (id) => {
    deleteCountry({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: GET_ALL_COUNTRIES,
          variables: { id: page * LIMIT_PAGINATION, limit: LIMIT_PAGINATION },
        },
      ],
    });
  };
  const removeTrip = (id) => {
    deleteTrip({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: LIST_TRIP,
          variables: { id: page * LIMIT_PAGINATION, limit: LIMIT_PAGINATION },
        },
      ],
    });
  };
  const [deleteCategory] = useMutation(DELETE_TRIP_CATEGORY);
  const removeCategory = (id) => {
    deleteCategory({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: GET_TRIP_CATEGORIES,
          variables: { page: page * LIMIT_PAGINATION, limit: LIMIT_PAGINATION },
        },
      ],
    });
  };
  const [deleteContext] = useMutation(DELETE_TRIP_CONTEXT);
  const removeContext = (id) => {
    deleteContext({
      variables: {
        id: id,
      },
      refetchQueries: [
        {
          query: GET_TRIP_CONTEXTS,
          variables: { page: page * LIMIT_PAGINATION, limit: LIMIT_PAGINATION },
        },
      ],
    });
  };

  useEffect(() => {
    const onClickWindow = (e: any) => {
      if (options.current.contains(e.target as Node)) {
        return;
      }

      setIsOptionsActivated(false);
    };
    window.addEventListener("click", onClickWindow);

    return () => window.removeEventListener("click", onClickWindow);
  }, []);

  const updateHandler = () => {
    if (path.includes("contexts") || path.includes("categories")) {
      setData(data);
    } else if (path.includes("locations")) {
      navigate(`${path}/${isCity ? "city" : "country"}/${data.id}`);
    } else {
      navigate(path + "/" + data.id);
    }
  };

  const deleteHandler = async () => {
    if (
      path.includes("deals") &&
      !path.includes("contexts") &&
      !path.includes("categories")
    ) {
      try {
        await removeTrip(data.id);
        await Toast.fire({
          icon: "success",
          title: "Deal supprimé",
        });
      } catch (e) {
        await MySwal.fire({
          icon: "error",
          title: "Oops...",
          // @ts-ignore
          text: "Something went wrong: \n " + e.message,
        });
      }
    } else if (path.includes("coupon")) {
      try {
        await removeCoupon(data?.id);
        await Toast.fire({
          icon: "success",
          title: "Coupon supprimé",
        });
      } catch (e) {
        await MySwal.fire({
          icon: "error",
          title: "Oops...",
          // @ts-ignore
          text: "Something went wrong: \n " + e.message,
        });
      }
    } else if (path.includes("contexts")) {
      try {
        await removeContext(data.id);
        await Toast.fire({
          icon: "success",
          title: "Contexte supprimé",
        });
      } catch (e) {
        await MySwal.fire({
          icon: "error",
          title: "Oops...",
          // @ts-ignore
          text: "Something went wrong: \n " + e.message,
        });
      }
    } else if (path.includes("categories")) {
      try {
        await removeCategory(data.id);
        await Toast.fire({
          icon: "success",
          title: "Catégorie supprimée",
        });
      } catch (e) {
        await MySwal.fire({
          icon: "error",
          title: "Oops...",
          // @ts-ignore
          text: "Something went wrong: \n " + e.message,
        });
      }
    }
    if (path.includes("locations")) {
      if (isCity) {
        try {
          await removeCity(data.id);
          await Toast.fire({
            icon: "success",
            title: "Ville supprimée",
          });
        } catch (e) {
          await MySwal.fire({
            icon: "error",
            title: "Oops...",
            // @ts-ignore
            text: "Something went wrong: \n " + e.message,
          });
        }
      } else {
        try {
          await removeCountry(data.id);
          await Toast.fire({
            icon: "success",
            title: "Pays supprimé",
          });
        } catch (e) {
          await MySwal.fire({
            icon: "error",
            title: "Oops...",
            // @ts-ignore
            text: "Something went wrong: \n " + e.message,
          });
        }
      }
    }
  };
  const shadowHandler = async () => {
    try {
      const response = await shadowUser({ variables: { userID: data.id } });

      const url = response?.data?.shadowUser;
      if (url) {
        // @ts-ignore
        window.open(url, "_blank").focus();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <img
        src="/svg/options.svg"
        alt="options"
        onClick={() => setIsOptionsActivated(!isOptionsActivated)}
        ref={options}
        style={{ cursor: "pointer" }}
      />
      {isOptionsActivated && (
        <div className={style["options"]}>
          <div className={style["options-option"]} onClick={updateHandler}>
            <img src="/svg/update.svg" alt="update" /> Modifier
          </div>
          {!isAUser && (
            <div className={style["options-option"]} onClick={deleteHandler}>
              <img src="/svg/delete.svg" alt="delete" /> Supprimer
            </div>
          )}
          {!isAUser && (
            <div className={style["options-option"]} onClick={deleteHandler}>
              <img src="/svg/bx_hide.svg" alt="delete" />
              Masquer
            </div>
          )}
          {isAUser && (
            <div className={style["options-option"]} onClick={shadowHandler}>
              <img src="/svg/user-black.svg" alt="user" /> Shadow User
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};
export default ListOptions;
